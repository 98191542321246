<template>
  <div class="px-4 overflow-hidden">
    <div class="pt-4 title">
      <h3>Sign In</h3>
    </div>

    <template v-if="layouts.actives.login">
      <div class="f-s-16 mt-2">
        Please enter the one-time-passcode sent to your email account.
      </div>
      <v-form ref="form" @submit.prevent>
        <v-text-field
          v-model="dataSources.current.oneTimeCode"
          label="One-Time Passcode"
          :rules="dataSources.current.oneTimeCodeRule"
        ></v-text-field>
      </v-form>
      <div class="f-s-16 text-right">
        <v-menu
          offset-y
          min-width="200"
          :disabled="dataSources.current.countdown > 0"
          :key="dataSources.current.countdown"
        >
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
              :style="dataSources.current.countdown > 0 ? 'color: grey' : 'color: var(--theme_primary)'"
            >Didn't receive the email?</span>
            <span
              class="text-red"
              v-if="dataSources.current.countdown > 0"
            > ({{dataSources.current.countdown }}s)</span>
          </template>
          <div class="menu-box">
            <div class="menu-box-triangle"></div>
            <v-layout class="menu-item-content">
              <v-flex class="menu-item" @click="clickResend">
                <v-icon size="40" color="blue" class="mr-2">mdi-reload</v-icon>
                <div class="field-label">Resend Email</div>
              </v-flex>
            </v-layout>
          </div>
        </v-menu>
      </div>
      <web-btn class="mt-4 width-full" @click="clickContinue">
        Continue
      </web-btn>
    </template>

    <template v-else>
      <v-alert
        v-if="layouts.actives.alert"
        class="mt-2"
        dense
        text
        outlined
        color="error"
      >
        {{ dataSources.current.errorMsg }}
      </v-alert>
      <web-text-field
        label="Enter Email"
        v-model="dataSources.current.email"
      >
      </web-text-field>
      <web-text-field
        label="Enter Password"
        :append-icon="layouts.actives.showPwd ? 'mdi-eye' : 'mdi-eye-off'"
        :type="layouts.actives.showPwd ? 'text' : 'password'"
        v-model="dataSources.current.password"
        @click:append="layouts.actives.showPwd = !layouts.actives.showPwd"
      >
      </web-text-field>

      <a class="pull-right f-s-13" @click="$router.push('/send-reset-link')">Forgot Password?</a>

      <web-btn
        class="mt-4 width-full"
        @click="clickSignIn"
      >
        Sign In
      </web-btn>

      <div class="d-flex align-center my-8">
        <v-divider></v-divider>
        <span class="px-4">Or</span>
        <v-divider></v-divider>
      </div>

      <div class="text-center">
        Not registered yet?
        <a class="f-w-600" @click="$router.push('/send-set-link')"
        >Create Account</a
        >
      </div>
    </template>

  </div>
</template>

<script>
import WebTextField from '@/components/base/WebTextField'
import WebBtn from '../../components/base/WebBtn.vue'
import { AccountApi } from '@/api'
import { Cache, DataType, Tool, Crypto, Popup } from '@/assets/js/util'

export default {
  components: { WebTextField, WebBtn },
  data () {
    return {
      assists: {
        tool: new Tool(),
        cache: new Cache(),
        popup: new Popup()
      },
      dataSources: {
        current: {
          errorMsg: 'Incorrect email or password.',
          email: '',
          password: '',
          oneTimeCode: '',
          oneTimeCodeRule: [v => /^\d{6}$/.test(v) || 'One-Time Passcode is invalid.'],
          countdown: 60
        }
      },
      layouts: {
        actives: {
          login: false,
          alert: false,
          showPwd: false
        }
      }
    }
  },
  methods: {
    clickSignIn () {
      const { email, password } = this.dataSources.current

      if (
        !DataType.ValidationRules.EMAIL.test(email) ||
        this.assists.tool.isEmpty(email) ||
        this.assists.tool.isEmpty(password)
      ) {
        this.assists.popup.popupForError('Incorrect email or password.')
        return
      }

      AccountApi.signIn(
        {
          portfolioId: this.$store.getters.getPortfolioId,
          email,
          password: Crypto.md5(password)
        },
        (res) => {
          const { mfaStatus, token } = res

          if (mfaStatus === DataType.StatusEnum.ENABLE.value) {
            this.layouts.actives.login = true
            this.countDown()
          } else {
            this.assists.cache.setAccessToken(token)
            this.$router.push('/home')
          }
        },
        (err) => {
          this.assists.popup.popupForError(err.message)
        }
      )
    },
    clickContinue () {
      this.$refs.form.validate()
      if (this.$refs.form.validate()) {
        const { email, oneTimeCode } = this.dataSources.current
        AccountApi.verifyLoginCode({
          email,
          portfolioId: this.$store.getters.getPortfolioId,
          code: oneTimeCode
        }, (res) => {
          this.assists.cache.setAccessToken(res)
          this.$router.push('/home')
        }, (err) => {
          this.assists.popup.popupForError(err.message)
        })
      }
    },
    clickResend () {
      AccountApi.resendVerificationCode({
        email: this.dataSources.current.email,
        portfolioId: this.$store.getters.getPortfolioId
      }, () => {
        this.countDown()
      }, (err) => {
        this.assists.popup.popupForError(err.message)
      })
    },
    countDown () {
      this.dataSources.current.countdown = 60
      this.timer = setInterval(() => {
        if (this.dataSources.current.countdown > 0) {
          this.dataSources.current.countdown--
        } else {
          clearInterval(this.timer)
        }
      }, 1000)
    }
  }
}
</script>

<style lang='sass' scoped>
.title
  height: 18vh

.menu-box
  height: 70px
  position: relative

  &-triangle
    width: 20px
    height: 20px
    border: 20px solid
    border-color: transparent transparent #eae9e9 transparent
    position: absolute
    left: 43%
    top: -40px

  .menu-item-content
    background-color: #eae9e9
    width: 100%
    height: 100%
    border-radius: 5px
    margin-top: 20px

  .menu-item
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer
    width: 50%

    .field-label
      font-weight: 500
      font-size: 16px

.v-menu__content
  box-shadow: 0 5px 5px -5px rgb(0 0 0 / 20%), 0 8px 10px 0 rgb(0 0 0 / 0%), 0 3px 0 0 rgb(0 0 0 / 0%)
</style>
